<template>
  <div>
    <div class="main-title">用户动态</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div class="list-head" data-v-69874ad0="">
          <p class="list-head-title" data-v-69874ad0="">温馨提示</p>
          <ul data-v-69874ad0=""></ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-input
            v-model="id"
            class="margin-l-sm"
            clearable
            placeholder="ID"
            size="mini"
            style="width: 160px"
          >
          </el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-input
            v-model="userName"
            class="margin-l-sm"
            clearable
            placeholder="用户名"
            size="mini"
            style="width: 160px"
          >
          </el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-select
            v-model="has_comment"
            clearable
            placeholder="是否有评论"
            size="small"
          >
            <el-option :value="1" label="是"> </el-option>
            <el-option :value="0" label="否"> </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-select
            v-model="is_recommend"
            clearable
            placeholder="是否推荐 "
            size="small"
          >
            <el-option :value="1" label="是"> </el-option>
            <el-option :value="0" label="否"> </el-option>
          </el-select>
        </span>

        <span class="fl-l">
          <el-input
            v-model="title"
            class="margin-l-sm"
            clearable
            placeholder="标题"
            size="mini"
            style="width: 160px"
          >
          </el-input>
        </span>

        <span class="fl-l">
          <el-select
            slot="prepend"
            v-model="status"
            class="margin-l-sm"
            clearable
            placeholder="选择状态"
            size="mini"
          >
            <el-option :value="1" label="待审核"></el-option>
            <el-option :value="2" label="通过"></el-option>
            <el-option :value="3" label="未通过"></el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-date-picker
            v-model="value2"
            :picker-options="pickerOptions"
            align="right"
            end-placeholder="结束日期"
            range-separator="至"
            size="small"
            start-placeholder="开始日期"
            type="daterange"
            unlink-panels
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>
        <span class="margin-l-sm fl-l">
          <gift
            :user-list="multipleSelection"
            field="user_id"
            size="small"
            type="2"
          />
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="dialogVisible = true"
            >一键审核</el-button
          >
        </span>

        <span class="fl-r"> </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :cell-class-name="rowItem"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          row-key="id"
          style="width: 100%; background: #fff"
          @current-change="handleCurrentChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            :reserve-selection="true"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column label="头像" width="150">
            <template slot-scope="scope">
              <el-avatar
                :size="50"
                :src="scope.row.user_name_avatar_url"
                icon="el-icon-user-solid"
                shape="square"
              >
              </el-avatar>
            </template>
          </el-table-column>
          <el-table-column label="用户名" property="index" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.user_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="标题" property="index">
            <template slot-scope="scope">
              <span>{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column label="预览图" property="index" width="200">
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.pic_data.length > 0 && scope.row.type === 1"
                :preview-src-list="picData(scope.row.pic_data)"
                :src="imageUrl + scope.row.pic_data[0]"
                fit="cover"
                style="width: 60px; height: 60px"
              ></el-image>
              <el-image
                v-if="scope.row.type === 2"
                :preview-src-list="[scope.row.video_pic]"
                :src="scope.row.video_pic"
                fit="cover"
                style="width: 60px; height: 60px"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="查看评论" property="index">
            <template slot-scope="scopes">
              <el-popover placement="bottom" trigger="click" width="1250">
                <div>
                  <el-table v-loading="commentLoading" :data="commentList">
                    <el-table-column
                      label="ID"
                      property="id"
                      width="80"
                    ></el-table-column>
                    <el-table-column
                      label="用户昵称"
                      property="user_name"
                    ></el-table-column>
                    <el-table-column
                      label="用户ID"
                      property="user_id"
                      width="80"
                    ></el-table-column>
                    <el-table-column
                      label="评论内容"
                      property="content"
                    ></el-table-column>
                    <el-table-column
                      label="被评论用户的ID"
                      property="comment_user_id"
                    ></el-table-column>
                    <el-table-column
                      label="被评论用户的内容"
                      property="comment_content"
                    ></el-table-column>
                    <el-table-column
                      label="被评论用户的昵称"
                      property="comment_user_name"
                    ></el-table-column>
                    <el-table-column
                      label="评论时间"
                      property="created_at"
                    ></el-table-column>
                    <el-table-column label="操作" property="address">
                      <template slot-scope="scope">
                        <span>
                          <el-link
                            type="primary"
                            @click="
                              commentForm = {
                                id: scope.row.id,
                                msg: 2,
                              };
                              commentFormDialog = true;
                            "
                            >评论</el-link
                          >
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination
                    :current-page="commentPage.page"
                    :page-size="commentPage.pageSize"
                    :page-sizes="[10, 20]"
                    :total="commentPage.total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    small
                    @size-change="setCommentPageSize"
                    @current-change="setCommentPage"
                  >
                  </el-pagination>
                </div>
                <el-link
                  v-show="scopes.row.comment > 0"
                  slot="reference"
                  type="primary"
                  @click="getCommentList(scopes.row)"
                  >查看评论
                </el-link>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="类型" property="index">
            <template slot-scope="scope">
              <span>{{ scope.row.type === 1 ? "图片" : "视频" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" property="index">
            <template slot-scope="scope">
              <span>{{
                scope.row.status === 1
                  ? "待审核"
                  : scope.row.status === 2
                  ? "通过"
                  : "未通过"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" property="created_at" width="200">
          </el-table-column>
          <el-table-column label="操作" property="address">
            <template slot-scope="scope">
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
              -
              <!-- <span>
                <router-link :to="'/userSocial/detail/' + scope.row.id" class="router-link">
                  <el-link type="primary">详情</el-link></router-link>
              </span> -->
              <span>
                <el-link
                  type="primary"
                  @click="
                    commentForm = {
                      id: scope.row.id,
                      msg: 1,
                    };
                    commentFormDialog = true;
                  "
                  >评论</el-link
                >
              </span>
              <span v-if="scope.row.type === 2">
                -
                <span>
                  <el-link type="primary" @click="showPlayDialog(scope.row)"
                    >播放</el-link
                  >
                </span>
              </span>

              -
              <span>
                <el-popover
                  v-model="scope.row.visible"
                  placement="top"
                  width="160"
                >
                  <div>
                    <el-button
                      size="mini"
                      type="success"
                      @click="setStatus(scope.row, 2)"
                      >通过</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="setStatus(scope.row, 3)"
                      >不通过</el-button
                    >
                  </div>
                  <el-link slot="reference" type="primary">审核</el-link>
                </el-popover>
              </span>
              <span v-if="scope.row.status === 2">
                -
                <el-link
                  v-if="scope.row.is_recommend === 0"
                  type="primary"
                  @click="toSetRecommend(scope.row.id)"
                  >推荐</el-link
                >
                <el-link
                  v-else
                  type="danger"
                  @click="toSetRecommend(scope.row.id)"
                  >取消推荐</el-link
                >
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogPlayVisible" append-to-body title="播放">
      <div v-loading="videoLoading">
        <div style="padding-bottom: 20px">{{ content }}</div>

        <video :src="videoUrl" controls="controls"></video>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="提示"
      width="30%"
    >
      <span>
        <el-radio v-model="isPass" :label="true">通过</el-radio>
        <el-radio v-model="isPass" :label="false">不通过</el-radio></span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="audit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="commentFormDialog"
      append-to-body
      title="评论"
      width="30%"
    >
      <el-form
        ref="form"
        :model="commentForm"
        label-width="80px"
        @submit.native.prevent
      >
        <el-form-item
          :rules="[
            { required: true, message: '请输入评论内容', trigger: 'blur' },
          ]"
          label="评论内容"
          prop="content"
        >
          <el-input v-model="commentForm.content"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="comment">评论</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import gift from "@/components/gift/index.vue";

export default {
  name: "list",
  components: { gift },
  data() {
    return {
      has_comment: "",
      is_recommend: "",
      videoLoading: false,
      dialogPlayVisible: false,
      dialogVisible: false,
      isPass: true,
      content: "",
      videoUrl: "",
      page: 1,
      pageSize: 10,
      total: 0,
      userName: "",
      searchType: 1,
      loading: false,
      imageUrl: config.imageUrl,
      commentLoading: false,
      commentList: [],
      commentPage: {
        page: 1,
        pageSize: 5,
        total: 0,
        id: null,
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      tableData: [],
      currentRow: null,
      commentForm: {},
      commentFormDialog: false,
      id: "",
      title: "",
      status: "",
      multipleSelection: [],
    };
  },
  methods: {
    ...mapActions("user", [
      "getUserSocialList",
      "getUserSocialDetail",
      "setUserSocialStatus",
      "socialVerify",
      "showComments",
      "socialComment",
      "replyComment",
    ]),
    ...mapActions("common", ["delete", "setRecommend"]),
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    async getCommentList(row) {
      this.commentLoading = true;
      if (row != undefined) {
        this.commentPage.id = row.id;
      }

      const { data } = await this.showComments(this.commentPage);
      this.commentList = data.list;
      this.commentPage.total = data.total;
      this.commentLoading = false;
    },
    picData(pics) {
      let arr = [];
      if (pics.length > 0) {
        pics.forEach((pic) => {
          arr.push(this.imageUrl + pic);
        });
      }
      return arr;
    },
    async comment() {
      let res;
      if (this.commentForm.msg == 1) {
        res = await this.socialComment(this.commentForm);
      } else {
        res = await this.replyComment(this.commentForm);
        this.getCommentList();
      }
      let res_info = res.res_info;
      if (res_info != "ok") return;
      this.getList();

      this.$message.success("评论成功");
      this.commentFormDialog = false;
    },
    setStatus(row, status) {
      let _this = this;
      let data = {
        id: row.id,
        status: status,
      };
      this.setUserSocialStatus(data).then((res) => {
        if (res.ret === 0) {
          _this.$message({
            message: "设置成功",
            type: "success",
          });
        } else {
          _this.$message({
            message: "设置失败",
            type: "error",
          });
        }

        this.getList();
      });
    },
    showPlayDialog(row) {
      this.videoLoading = true;
      this.getUserSocialDetail({ id: row.id }).then((res) => {
        this.videoUrl = res.data.videoUrl;
        this.content = res.data.content;
        this.videoLoading = false;
      });
      this.dialogPlayVisible = true;
    },
    hidePlayDialog() {
      this.dialogPlayVisible = false;
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    headeCellStyle({ row, rowIndex }) {
      return "{padding:0}";
    },
    rowItem() {
      return "row-item";
    },
    setBan(row, isBan) {},

    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    async audit() {
      let table = this.$refs.singleTable.selection;
      let arr = [];
      table.forEach((v) => {
        arr.push(v.id);
      });
      let form = {
        socialIds: arr,
        isPass: this.isPass,
      };
      const { res_info } = await this.socialVerify(form);
      this.dialogVisible = false;
      if (res_info != "ok") return;
      this.$message.success("审核成功");
      this.getList();
    },
    getList() {
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        created_at_start: this.value2 == null ? "" : this.value2[0],
        created_at_end: this.value2 == null ? "" : this.value2[1],
        name: this.userName,
        id: this.id,
        title: this.title,
        status: this.status,
        is_recommend: this.is_recommend,
        has_comment: this.has_comment,
      };
      this.loading = true;
      this.getUserSocialList(data).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;

        this.loading = false;
      });
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getCommentList();
    },
    setCommentPageSize(pageSize) {
      this.commentPage.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    setCommentPage(page) {
      this.commentPage.page = page;
      this.getCommentList();
    },
    deleted(row) {
      this.loading = true;
      this.delete({ id: row.id, model: "Social" }).then((res) => {
        this.getList();
        if (res.ret == 0) {
          this.$message.success("删除成功");
        }
      });
    },
    toSetRecommend(id) {
      this.loading = true;
      this.setRecommend({ id: id, model: "SocialModel" })
        .then((res) => {
          this.loading = false;
          if (res.ret == 0) {
            this.$message.success("操作成功");
            this.getList();
          }
        })
        .catch(() => {
          //this.$message.error('操作失败')
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style></style>